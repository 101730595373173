<template>
  <el-dialog
    :modal="false"
    title="查看采购入库单"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    fullscreen
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-alert
        :closable="false"
        :title="titleTip"
        type="success"
        style="width: 82%; margin-top: -70px; margin-left: 150px"
      ></el-alert>
      <el-form
        ref="form"
        :model="form"
        inline
        :disabled="!isCopy"
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="供应商:" prop="supp_id">
          <el-select
            v-model="form.supp_id"
            clearable
            placeholder="请选择供应商"
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in gysList"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经办人:" prop="operator_id">
          <el-select
            v-model="form.operator_id"
            clearable
            placeholder="请选择经办人"
            style="width: 180px"
          >
            <el-option
              v-for="(item, index) in jbrList"
              :key="index"
              :label="item.user_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库仓库:" prop="depot_id">
          <el-select
            v-model="form.depot_id"
            style="width: 180px"
            clearable
            placeholder="入库仓库"
          >
            <el-option
              v-for="(item, index) in ckList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <!-- <el-form-item prop="">
          <el-input
            placeholder="使用扫码器添加商品"
            style="width: 303px"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="" prop="minUnit">
          <el-checkbox v-model="minUnit">按小单位采购</el-checkbox>
        </el-form-item>
        <el-form-item prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            style="width: 303px"
          ></el-input>
        </el-form-item>
        <!-- 表格 -->
        <el-table
          ref="tableSort"
          stripe
          :data="list"
          :summary-method="getSummaries"
          show-summary
          height="360px"
        >
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :label="item.label"
            :prop="item.prop"
            width=""
            align="center"
          >
            <!-- 类型 -->
            <template v-if="item.prop == 'goods_type'" #default="{ row }">
              <el-select v-model="row.goods_type">
                <el-option
                  v-for="(typeItem, index2) in typeSelect"
                  :key="index2"
                  :label="typeItem.value"
                  :value="typeItem.id"
                ></el-option>
              </el-select>
            </template>
            <!-- 商品名称 -->
          </el-table-column>
        </el-table>
        <el-form-item>
          <el-checkbox
            v-model="form.auto_update_price"
            :true-label="1"
            :false-label="0"
          >
            生成采购入库单时重新计算商品成本
          </el-checkbox>
        </el-form-item>
        <div class="pay">
          <div class="left">
            <el-form-item label="金额合计:" style="width: 100%">
              <el-input v-model="totalM" style="width: 100px"></el-input>
              <!-- <span style="margin-left: 10px">优惠:{{ yhl }}元</span> -->
            </el-form-item>
            <!-- <el-form-item label="优惠后应付:" style="width: 100%">
              <el-input
                v-model="form.final_money"
                style="width: 100px"
              ></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="付款金额" style="">
              <el-input
                v-model="payInfo.amount"
                style="width: 100px"
              ></el-input>
            </el-form-item>
            <el-form-item label="" style="">
              <el-select
                v-model="payInfo.account_id"
                placeholder="付款账户"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in fkzhList"
                  :key="index"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" style="">
              <el-select
                v-model="payInfo.user_id"
                placeholder="选择付款人"
                style="width: 100px"
              >
                <el-option
                  v-for="(item, index) in fkrList"
                  :key="index"
                  :label="item.user_name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <i class="el-icon-circle-plus addbtn" @click="addInput"></i>
            </el-form-item> -->
          </div>
          <div class="right">
            <el-table stripe :data="list2">
              <el-table-column
                label="付款账户"
                prop="account"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="付款金额"
                prop="amount"
                width="auto"
                align="center"
              ></el-table-column>
              <el-table-column
                label="付款人"
                prop="create_name"
                width="auto"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="handlePrint">打印</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CG"
      :data_id="id"
      :type="1"
    ></ruilang-display-dialog>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import {
    jbrSelect,
    gysSelect,
    fkzhSelect,
    purchaseInsetDetail,
  } from '@/api/purchase'
  import { getAllSelect } from '@/api/advanceOrder'
  export default {
    name: 'Check',
    components: { RuilangDisplayDialog },
    data() {
      return {
        id: 0,
        allSum: 0,
        bill_code: '',
        create_at: '',
        showDialog: false,
        loading: false,
        minUnit: false,
        jbrList: [],
        gysList: [],
        isCopy: false,
        typeSelect: [
          {
            id: 1,
            value: '采购',
          },
          {
            id: 2,
            value: '赠送',
          },
        ],
        form: {
          supp_id: '',
          operator_id: '', //经办人id
          supp_id: '', //经销商id
          depot_id: '', //入库id
          bill_code: '', //单号
          remark: '', //备注
          auto_update_price: false,

          order_id: '',
          final_money: '',
          return_money: '', //退款金额
        },
        payInfo: {
          account_id: '',
          user_id: '',
          amount: '',
        },
        pay_info: [
          {
            account_id: '',
            account: '',
            user_id: '',
            create_name: '',
            amount: '',
          },
        ],
        ckList: [],
        // 表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '单位',
          '采购价',
          '采购数量',
          '金额',
          '条形码',
          '生产日期',
          '单位换算',

          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '类型',
            width: '100',
            prop: 'goods_type',
          },
          {
            order: 2,
            label: '商品名称',
            width: '220',
            prop: 'goods_name',
          },
          {
            order: 3,
            label: '规格',
            width: '90',
            prop: 'goods_specs',
          },
          {
            order: 4,
            label: '条形码',
            width: '160',
            prop: 'goods_code',
          },
          {
            order: 5,
            label: '生产日期',
            width: '200',
            prop: 'goods_production_date',
          },
          {
            order: 6,
            label: '单位换算',
            width: '100',
            prop: 'goods_uint_change',
          },
          {
            order: 3.1,
            label: '单位',
            width: '110', //'80',
            prop: 'unit_name',
          },
          {
            order: 3.2,
            label: '采购价',
            width: '140',
            prop: 'goods_price',
          },
          {
            order: 3.3,
            label: '采购数量',
            width: '90',
            prop: 'goods_num',
          },
          {
            order: 3.4,
            label: '金额',
            width: '90',
            prop: 'sum_money',
          },
          {
            order: 11,
            label: '备注',
            width: '220',
            prop: 'remark',
          },
        ],
        list: [
          {
            id: 1111,
            goods_type: 1,
            goods_type_text: '采购',
            goods_name: '蒙牛',
            goods_specs: '500ml',
            goods_code: '654456645',
            goods_uint_change: '1件=10箱',
            goods_production_date: '2020.04.23',
            unit_id: '1',
            unit_name: '瓶',
            goods_price: '111.00',
            goods_num: '11',
            sum_money: '2000.00',
            remark: '备注',
          },
          {
            id: 2222,
            goods_type: 2,
            goods_type_text: '采购',
            goods_name: '蒙牛12',
            goods_specs: '500ml',
            goods_code: '654456645',
            goods_uint_change: '1件=10箱',
            goods_production_date: '2020.04.23',
            unit_id: '1',
            unit_name: '瓶',
            goods_price: '222.00',
            goods_num: '220',
            sum_money: '2000.00',
            remark: '备注22222',
          },
        ],
        list2: [],
        yhl: 0,
        totalM: 0,
      }
    },
    computed: {
      // yhl() {
      //   console.log(this.final_money, this.allSum)
      //   return Number(this.allSum) - Number(this.form.final_money)
      // },
      titleTip() {
        return '单据号:' + this.bill_code + ' 下单时间： ' + this.create_at
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
      // 总额
      // allSum(val) {
      //   if (val) {
      //     this.form.final_money = val
      //     this.payInfo.amount = val
      //   }
      // },
      // 按最小单位
      minUnit(val) {
        console.log(val)
        if (val) {
          this.list.forEach((item) => {
            if (item.goods_name !== '') {
              // 单位变小单位
              item.unit_id = item.arr_unit.filter(
                (item2) => item2.unit_type == 1
              )[0].id
              // 编码变小编码
              item.goods_code = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0].unit_code
              //采购价变小单位采购价
              item.goods_price = item.arr_unit.filter(
                (item2) => item2.id == item.unit_id
              )[0].sell_price
              this.priceSum('', item)
            }
          })
        }
      },
    },
    created() {
      // 经办人下拉
      jbrSelect().then((res) => {
        this.jbrList = res.data.rows
      })
      // 供应商下拉
      gysSelect().then((res) => {
        this.gysList = res.data.rows
      })
      // 仓库下拉
      getAllSelect().then((res) => {
        this.ckList = res.data.depot
      })
      // 付款账户
      fkzhSelect().then((res) => {
        console.log(res)
        this.fkzhList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await purchaseInsetDetail({
          order_id: this.id,
        })
        console.log(data)

        let dada = data.rows
        console.log(dada)
        // tips
        this.bill_code = dada.bill_code
        this.create_at = dada.create_at
        this.check_at = dada.check_at
        // 供应商
        this.form = JSON.parse(JSON.stringify(dada))
        delete this.form.detail_info
        delete this.form.log
        this.form.order_id = this.id

        this.list = dada.detail_info.detail
        this.yhl = data.rows.discount_money
        this.totalM = data.rows.total_amount
        this.list2 = dada.log.log
        this.loading = false
      },
      // 表格合计
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' 元'
          } else {
            sums[index] = 'N/A'
          }
        })
        let a = []
        sums.forEach((item, idx) => {
          if (idx == 7) {
            a.push(item)
            this.allSum = item.split(' 元')[0]
          } else if (idx == 6) {
            a.push(item.split(' 元')[0])
          } else {
            a.push('')
          }
        })
        return a
      },
      // 采购价事件
      priceSum(val, row) {
        row.sum_money = row.goods_price * row.goods_num
      },
      close() {
        this.showDialog = false
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        row.goods_price = price
      },

      chooseGoodsKeyword(val, row) {},
      copyRow(index, row) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      deleteRow(index, row) {
        if (this.list.length <= 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      // 商品搜索添加rows
      addRows(val) {},
      // 商品选中
      selectGoods(val, row) {
        console.log(val, row)
        row.goods_id = val.goods_id
        row.goods_name = val.goods_name
        row.goods_uint_change = val.unit_cv
        row.goods_specs = val.specs
        row.goods_production_date = val.goods_production_date
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pay {
    display: flex;
    justify-content: flex-start;
    .left {
      width: 50%;
    }
    .right {
      width: 50%;
    }
  }
  .addbtn {
    position: absolute;
    top: 0;
    right: -50px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
  .el-dialog__body {
    padding: 0px 20px !important;
  }
</style>
